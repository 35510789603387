// fabric_data is defined in the template

export const colorCardPage = (fabricData) => {
  const $colorSwatchSvg = $('#color-swatch-template').html();
  $('.color-swatch').append($colorSwatchSvg);

  const $fabricStyleSelect = $('#js-fabric-style-select');
  const $finishStyleSelect = $('#js-finish-style-select');

  Object.keys(fabric_data).forEach(function(fabricStyle) {
    $fabricStyleSelect.append(`<option value="${fabricStyle}">${fabricStyle}</option>`);
  });


  const grid = $('#color-swatches').isotope({
    itemSelector: '.portfolio-item',
    layoutMode: 'fitRows',
    getSortData: {
      fabric: '[data-fabric-style]',
      finish: '[data-finish-style]',
      weight: function( itemElem ) {
        var weight = $( itemElem ).find('.weight').text();
        return parseFloat( weight.replace( /[\(\)]/g, '') );
      }
    }
  });

  // filter color swatches:
  $finishStyleSelect.add($fabricStyleSelect).on('change', function(e) {
    var fabric = $fabricStyleSelect.val();
    var finish = $finishStyleSelect.val();
    var filter = function() {
      if (finish) {
        return $(this).data('finish-style') === finish;
      } else if (fabric) {
        return $(this).data('fabric-style') === fabric;
      } else {
        return true;
      }
    };
    grid.isotope({ filter: filter });
  });

  $fabricStyleSelect.on('change', function(e) {
    var emptyOption = `<option value="">Select Finish Style</option>`;
    var finishStyleHtml = (fabric_data[e.target.value] || []).map(function(finishStyle) {
      return `<option value="${finishStyle}">${finishStyle}</option>`;
    }).join('');

    $finishStyleSelect.html(emptyOption + finishStyleHtml);
  });

};
