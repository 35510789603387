export const smallCutsPage = () => {
  //const domain = "http://ec2-23-22-19-222.compute-1.amazonaws.com:3000";
  const domain = "https://gims-converter.herokuapp.com/";

  const defaultFinishStyleId  = "59"; // 40120 regular nylon
  const finishStyleUrl = `${domain}/small_cuts/finish_style_selector?finish_style_id=${defaultFinishStyleId}`; // finish_style_id - preselect finish style in the <select> tag returned by the server
  const smallCutsUrl   = `${domain}/small_cuts.json?finish_style=${defaultFinishStyleId}&sku=`;
  let datatable;


  const renderTable = function(data) {
    //console.log(data)
    if (data && data[0] && data[0][1] === 'Sku') {
      data = data.splice(1, data.length - 1)
    }
    if (!datatable) {
      datatable = $( '#datatable' ).DataTable({
        lengthChange: false,
        pageLength: 20,
        paging: false,
        responsive: true,
        language: {
          emptyTable: `No small rolls found – <u><a target="_blank" href="/contact_us.html">contact</a></u> Glaser Mills to double check for this color`
        }
      });

      $("#table-search").on("keyup search input paste cut", function() {
         datatable.search(this.value).draw();
      });
    };

    datatable.clear();
    datatable.rows.add(data);
    datatable.draw();
  };

  //$('#finish_style_select_container').load(finishStyleUrl, function(resp, status) {
    //if (status === 'error') {
      //renderTable([]);
    //} else {
      //$(this).find('select').on('change', function(e) {
        //const finishStyleId = $(e.target).val();
        //const skusUrl = `${domain}/warehouse_rolls/load_skus_for_finish_style?selected_sku=9&finish_style_id=${finishStyleId}&disable_all_option=false`;

        //$('#skus_select_container').load(skusUrl, function(resp, status) {
          //if (status === 'error') {
            //renderTable([]);
          //}
        //});

      //}).trigger('change')
    //}
  //});

  const skusSelectContainer = $('#skus_select_container');

  skusSelectContainer.on('change', 'select', function(e) {
    var skuId = $(e.target).val();

    $.ajax(smallCutsUrl + skuId, {
      method: 'GET',
      format: 'json'
    }).then(renderTable);

  }).trigger('change');

  $('#finish_style_select_container').on('change', 'select', function(e) {
    const finishStyleId = $(e.target).val();
    const skusUrl = `${domain}/warehouse_rolls/load_skus_for_finish_style?selected_sku=9&finish_style_id=${finishStyleId}&disable_all_option=false`;
    skusSelectContainer.append(`<select disabled><option>Loading, please wait...</option></select>`);

    //renderTable([]);
    skusSelectContainer.load(skusUrl, function(resp, status) {
      if (status !== 'error') {
        skusSelectContainer.find('select').trigger('change');
      }
    });
  });

  renderTable([]);
  $('#finish_style_select_container').load(finishStyleUrl, function(resp, status) {
    $('#finish_style_select_container select').trigger('change');
  });

}
