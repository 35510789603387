// products var is defined in the template

export const productTypesPage = (products) => {
  var productTemplate = function(params) {
    var weight      = params.weight ? `- <span class="badge badge-pill badge-primary">${params.weight}</span>` : "";
    var description = params.description ? `<p class="m-b-5">${params.description}</p>` : "";
    var use         = params.use ? `<p><b>Use:</b> ${params.use.split(',').map(i => '<span class="badge badge-light2">'+i+'</span>').join(" ") }</p>` : "";
    return `<div class="border-bottom">
    <div class="card-body">
      <h5>${params.name} ${weight}</h5>
      ${description}
      ${use}
    </div>
  </div>`;
  };

  function unique(array) {
    return $.grep(array, function(el, index) {
      return index === $.inArray(el, array);
    });
  };


  /**
   * @param {array} attributeIndexes - array of numbers representing orginal indexes of attributes in the original csv
   example: [0, 1, 5] - represents 3 search attributes' indexes activated for filtering out products
   */
  var renderProducts = function(attributeIndexes) {
    var filteredProducts = products.filter(function(product) {
      return attributeIndexes.map(function(i) {
        return product.attributes[i];
      }).indexOf(true) !== -1; // OR logic
      //}).indexOf(false) === -1; // AND logic
    });

    $('#js-products-count').html(filteredProducts.length);

    $('#js-product-list').html(
      filteredProducts.map(function(p) {
        return productTemplate(p);
      }).join('')
    );
  };

  // init data here
  var filters   = $('#js-product-filters');
  var apply_all = $('#js-apply-all');

  $(function() {

    $('label.p-checkbox').on('keypress', function(e) {
      let isEnterOrSpace = e.keyCode === 32 || e.keyCode === 13;
      if (isEnterOrSpace) {
        e.preventDefault();
        var state = $(this).find('input:checkbox').prop('checked');
        $(this).find('input:checkbox').prop('checked', !state).trigger('change');
      }
    });

    apply_all.on('change', function(e) {
      filters.find('input').prop({ checked: e.target.checked });
      $(filters.find('input').get(0)).trigger('change');//prop({ checked: e.target.checked });
    });

    filters.on('change', 'input', function(e) {
      var uniqVals = unique(
        filters.find('input').map(function(i, checkbox) { return checkbox.checked })
      );

      apply_all.prop({ indeterminate: length === 2, checked: $(e.target).prop('cheched') });

      if (uniqVals.length === 2) {
        apply_all.prop({ indeterminate: true, checked: true });
      } else {
        apply_all.prop({ indeterminate: false, checked: uniqVals[0] });
      }

      var selectedValues = $('input:checked').map((i, ch) => parseInt(ch.value)).toArray()
      renderProducts(selectedValues);
    });

    renderProducts([]); //no filters: show all by default
  });
};
